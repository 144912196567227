import { closeSnackbar, enqueueSnackbar, SnackbarMessage, VariantType } from 'notistack';
import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const dismiss = (key: any) => () => {
    closeSnackbar(key);
};

export default {
    success(msg: string) {
        this.toast(msg, 'success');
    },
    warning(msg: string) {
        this.toast(msg, 'warning');
    },
    info(msg: string) {
        this.toast(msg, 'info');
    },
    error(msg: string) {
        this.toast(msg, 'error', 0);
    },
    toast(msg: SnackbarMessage, variant: VariantType = 'default', duration = 5) {
        enqueueSnackbar(msg, {
            variant,
            autoHideDuration: duration ? duration * 1000 : null,
            style: { whiteSpace: 'pre-line' },
            action: (key) => <IconButton onClick={dismiss(key)}><CloseIcon sx={{ color: 'white' }} /></IconButton>,
        });
    },
};
