import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { User as OidcUser } from 'oidc-client-ts';
import { useAuth0, User as Auth0User } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { loginCompleted, loginStarted, logout, PortalUser } from '../auth/authSlice';
import { MessageType } from './useBroadcastChannel';
import SnackbarUtils from '../snackbar-utils';
import { useAppDispatch } from '../../../app/hooks';
import { useLazyUserContextControllerRetrieveUserContextQuery } from '../../../services';
import useMessageBroadcaster from './useMessageBroadcaster';
import { ApiAuth } from '../../../services/ApiAuth';
import usePortalAuthentication from './usePortalAuthentication';

const roleClaim = 'https://bodd.io/roles';
const merchantIdClaim = 'https://bodd.io/merchant/merchant_id';

export enum AuthenticationModes {
    Auth0 = 'auth0',
    IdentityServer = 'identityserver',
    IdentityServerPortalAuth = 'identityserver-portalauth',
}

// changing this requires a compatible authority configured - this includes downstream services.
export const hardCodedAuthMode: AuthenticationModes = AuthenticationModes.Auth0;

function fromOidcUser(user: OidcUser): PortalUser {
    const portalUser = {
        userId: user.profile.sub,
        merchantId: null,
        name: user.profile.name,
        email: user.profile.email || user.profile.preferred_username,
        firstName: user.profile.given_name,
        profileImgUrl: user.profile.picture,
        roles: [],
    };
    return portalUser;
}

function fromAuth0User(user: Auth0User): PortalUser {
    const portalUser = {
        userId: user.sub,
        merchantId: null,
        name: user.name,
        email: user.email,
        firstName: user.given_name,
        profileImgUrl: user.picture,
        roles: [],
    };
    return portalUser;
}

export interface AuthWrapper {
    login: (opts?: { appState: { returnTo: string } }) => Promise<void>;
    loginSilent: () => Promise<Auth0User | OidcUser | null>;
    logout: () => void;
    isLoading: boolean;
    isAuthenticated: boolean;
    error: Error | undefined;
    getClaims: () => Promise<{ [key: string]: any } | undefined>;
    user: Auth0User | OidcUser | undefined;
}

export default function useAuthentication() {
    if (hardCodedAuthMode === AuthenticationModes.IdentityServerPortalAuth) {
        return usePortalAuthentication();
    }

    const [retrieveUserContext] = useLazyUserContextControllerRetrieveUserContextQuery();
    const { broadcast } = useMessageBroadcaster();

    const isAuth0 = hardCodedAuthMode === AuthenticationModes.Auth0;
    const navigate = useNavigate();
    let auth: AuthWrapper;
    if (isAuth0) {
        const {
            getAccessTokenSilently,
            isAuthenticated,
            isLoading,
            error,
            logout: handleLogout,
            loginWithRedirect,
            user,
            getIdTokenClaims,
        } = useAuth0();

        auth = {
            isAuthenticated,
            isLoading,
            error,
            login: loginWithRedirect,
            logout: () => handleLogout({ logoutParams: { returnTo: window.location.origin } }),
            getClaims: getIdTokenClaims,
            user,
            loginSilent: async () => {
                if (!user && !isLoading) {
                    if (window.location.pathname.length > 1) {
                        navigate(`/?returnTo=${window.location.pathname}`); // hacky
                    }
                }
                return null;
            },
        };

        ApiAuth.tokenFetcher = () => {
            if (isAuthenticated) {
                return getAccessTokenSilently();
            }
            return Promise.resolve('');
        };
    } else {
        const {
            removeUser,
            signinRedirect,
            signoutRedirect,
            user,
            isAuthenticated,
            isLoading,
            error,
            signinSilent,
        } = useAuth();

        const oidClogout = () => {
            removeUser();
            signoutRedirect({
                id_token_hint: user?.id_token,
                post_logout_redirect_uri: window.location.origin,
            });
        };

        ApiAuth.tokenFetcher = () => {
            if (isAuthenticated) {
                return Promise.resolve(user?.access_token ?? '');
            }
            return Promise.resolve('');
        };

        const loginSilent = () => signinSilent().then((r) => {
            if (r === null) {
                if (window.location.pathname.length > 1) {
                    navigate(`/?returnTo=${window.location.pathname}`); // hacky
                }
            }
            return r;
        }, () => null);

        auth = {
            isAuthenticated,
            isLoading,
            error,
            login: (opts) => signinRedirect({ state: opts?.appState }), // map auth0 state format...
            logout: oidClogout,
            getClaims: () => new Promise((resolve) => {
                const claims = (auth.user?.profile ?? {}) as { [key: string]: any };
                resolve(claims);
            }),
            user: user ?? undefined,
            loginSilent,
        };
    }

    const dispatch = useAppDispatch();

    const getRolesFromIdToken = (token: { [claim: string]: any }) => {
        let roles = token[roleClaim] || token.role || [];
        if (roles && !Array.isArray(roles)) { // if there is a single claim, it is currently interpreted as string.  need to look how to set as collection...
            roles = [roles];
        }
        return roles;
    };

    useEffect(() => {
        const updateAuth = async () => {
            if (auth.isLoading) {
                dispatch(loginStarted());
            } else if (auth.isAuthenticated) {
                const idToken = await auth.getClaims();
                const roles = getRolesFromIdToken(idToken!);
                const merchantId = idToken![merchantIdClaim] || idToken!.merchant_id;
                const userContext = await retrieveUserContext().unwrap();
                dispatch(loginCompleted({
                    user: isAuth0 ? fromAuth0User(auth.user as Auth0User) : fromOidcUser(auth.user as OidcUser),
                    roles,
                    merchantId,
                    merchantContext: userContext,
                }));
                broadcast({ type: MessageType.Login });
            } else {
                if (auth.error && auth.error.message === 'user is blocked') {
                    SnackbarUtils.error('Your account has been locked');
                }
                dispatch(logout());
            }
        };
        updateAuth();
    }, [auth.isAuthenticated, auth.isLoading]);

    return {
        isAuthenticated: auth.isAuthenticated,
        isLoading: auth.isLoading,
        login: auth.login,
        logout: auth.logout,
        error: auth.error,
        loginSilent: auth.loginSilent,
    };
}
