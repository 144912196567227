import { createTheme } from '@mui/material';
import boddColours from '../../../variables.module.scss';

// noinspection ES6UnusedImports
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles' {
    interface Palette {
        white: Palette['primary'];
        grey10: Palette['primary'];
        grey25: Palette['primary'];
        grey50: Palette['primary'];
        grey100: Palette['primary'];
        grey200: Palette['primary'];
        grey300: Palette['primary'];
        grey400: Palette['primary'];
        grey500: Palette['primary'];
        grey600: Palette['primary'];
        boddBlack: Palette['primary'];
        errorRed: Palette['primary'];
        successGreen: Palette['primary'];
        warningOrange: Palette['primary'];
        brandGreen: Palette['primary'];
        brandBlue: Palette['primary'];
        brandYellow: Palette['primary'];
        brandRed: Palette['primary'];
        brandPink: Palette['primary'];
    }

    interface PaletteOptions {
        white: PaletteOptions['primary'];
        grey10: PaletteOptions['primary'];
        grey25: PaletteOptions['primary'];
        grey50: PaletteOptions['primary'];
        grey100: PaletteOptions['primary'];
        grey200: PaletteOptions['primary'];
        grey300: PaletteOptions['primary'];
        grey400: PaletteOptions['primary'];
        grey500: PaletteOptions['primary'];
        grey600: PaletteOptions['primary'];
        boddBlack: PaletteOptions['primary'];
        errorRed: PaletteOptions['primary'];
        successGreen: PaletteOptions['primary'];
        warningOrange: PaletteOptions['primary'];
        brandGreen: PaletteOptions['primary'];
        brandBlue: PaletteOptions['primary'];
        brandYellow: PaletteOptions['primary'];
        brandRed: PaletteOptions['primary'];
        brandPink: PaletteOptions['primary'];
    }

    interface TypographyVariants {
        formHeading: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        formHeading?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    formHeading: true;
  }
}

const defaultTheme = createTheme({});

// eslint-disable-next-line import/prefer-default-export
export const theme = createTheme({
    palette: {
        primary: {
            main: boddColours.brandBlue,
        },
        secondary: {
            main: boddColours.brandRed,
        },
        error: {
            main: boddColours.errorRed,
        },
        warning: {
            main: boddColours.warningOrange,
        },
        info: { main: boddColours.brandBlue },
        success: { main: boddColours.successGreen },
        text:
            {
                primary: boddColours.boddBlack,
                secondary: boddColours.grey500,
            },
        background: { default: boddColours.grey10 },
        white: {
            main: boddColours.white,
        },
        grey10: {
            main: boddColours.grey10,
        },
        grey25: {
            main: boddColours.grey25,
        },
        grey50: {
            main: boddColours.grey50,
        },
        grey100: {
            main: boddColours.grey100,
        },
        grey200: {
            main: boddColours.grey200,
        },
        grey300: {
            main: boddColours.grey300,
        },
        grey400: {
            main: boddColours.grey400,
        },
        grey500: {
            main: boddColours.grey500,
        },
        grey600: {
            main: boddColours.grey600,
        },
        boddBlack: {
            main: boddColours.boddBlack,
        },
        errorRed: {
            main: boddColours.errorRed,
        },
        successGreen: {
            main: boddColours.successGreen,
        },
        warningOrange: {
            main: boddColours.warningOrange,
        },
        brandGreen: {
            main: boddColours.brandGreen,
        },
        brandBlue: {
            main: boddColours.brandBlue,
        },
        brandYellow: {
            main: boddColours.brandYellow,
        },
        brandRed: {
            main: boddColours.brandRed,
        },
        brandPink: {
            main: boddColours.brandPink,
        },
    },
    typography: {
        h1: {
            fontFamily: 'Poppins',
            fontWeight: '700',
            fontSize: '3.125rem',
            lineHeight: '123.5%',
            letterSpacing: '0.25px',
            marginBottom: '0.5rem',
        },
        h2: {
            fontFamily: 'Poppins',
            fontWeight: '600',
            fontSize: '1.688rem',
            lineHeight: '116.7%',
            marginBottom: '0.5rem',
        },
        h3: {
            fontFamily: 'Poppins',
            fontWeight: '600',
            fontSize: '1.25rem',
            lineHeight: '116.7%',
            marginBottom: '0.5rem',
        },
        h4: {
            fontFamily: 'Poppins',
            fontWeight: '600',
            fontSize: '1rem',
            lineHeight: '150%',
            letterSpacing: '0.15px',
            marginBottom: '0.5rem',
        },
        formHeading: {
            fontFamily: 'Poppins',
            fontWeight: '600',
            fontSize: '1rem',
            lineHeight: '150%',
            letterSpacing: '0.15px',
            marginBottom: '0.5rem',
        },
    },
    components: {
        MuiAlert: {
          styleOverrides: {
              standardError: {
                  color: boddColours.boddBlack,
              },
          },
        },
        MuiDatePicker: {
            defaultProps: {
                format: 'DD-MM-YYYY',
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                size: 'small',
            },
            styleOverrides: {
                root: {},
            },

        },
        MuiFormControl: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                    maxWidth: '300px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                      '.MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible div.MuiDataGrid-cell': {
                        borderBottomColor: `${boddColours.grey50}`,
                      },
                },
            },
        },
        MuiSvgIcon: {
          styleOverrides: {
              root: {
              },
          },
        },
        MuiIconButton: {
          styleOverrides: {
              root: {
                  color: boddColours.grey500,
              },
          },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        background: boddColours.brandLightBlue,
                        },
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    '&:not(:last-child)': {
                        marginBottom: '1rem',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: boddColours.grey25,
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontWeight: defaultTheme.typography.fontWeightMedium,
                    padding: '8px 24px',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '8px 24px',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '8px 24px',
                },
            },
        },
        MuiPagination: {
            defaultProps: {
                variant: 'outlined',
                shape: 'rounded',
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    padding: '8px 24px',
                    '> tr > th': {
                        fontWeight: defaultTheme.typography.fontWeightBold,
                    },
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                  formHeading: 'h4',
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    border: '1px solid',
                    borderColor: boddColours.grey50,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                contained: {
                    color: boddColours.white,
                },
                root: {
                    textTransform: 'none',
                    '&.Mui-disabled': {
                        backgroundColor: boddColours.grey25,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'always',
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: boddColours.grey25,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    zIndex: 1300,
                    height: 39,
                    border: 0,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    marginBottom: 0,
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    marginBottom: '4px',
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    backgroundColor: boddColours.grey50,
                    borderRadius: '4px',
                    border: 0,
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                    margin: 4,
                    borderRadius: '4px',
                    '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: boddColours.brandBlue,
                    },
                    border: 0,
                    '&.MuiToggleButtonGroup-grouped': {
                        borderRadius: '4px !important',
                    },
                },
            },
        },
    },
});
